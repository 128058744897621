/* 커스텀 scss 사용영역 */

.fs-1{
    font-size: 35px !important;
}

.fs-2{
    font-size: 25px !important;
}

.fs-3{
    font-size: 20px !important;
}

.fs-4{
    font-size: 16px !important;
}

.fs-5{
    font-size: 14px !important;
}

.fs-6{
    font-size: 12px !important;
}


.w-10 {
    width: 10% !important;
}

.w-20 {
    width: 20% !important;
}

.w-30 {
    width: 30% !important;
}

.w-40 {
    width: 40% !important;
}

.w-50 {
    width: 50% !important;
}

.w-60 {
    width: 60% !important;
}

.w-70 {
    width: 70% !important;
}

.w-80 {
    width: 80% !important;
}

.w-90 {
    width: 90% !important;
}

.w-100 {
    width: 100% !important;
}

.login-content{
    padding: 78px 30px 0 !important;
}

.margin-center{
    margin: 0 auto !important;
}

.navbar-absoluted-none{
    display: none !important;
}

label {
    font-size:12pt !important;
    margin-bottom:8pt !important;
}

.PENDING,.REVIEWING,.POLLING{ 
    background-color:#E5E5E5;
    color:#000;
}

.DROPPED{
  background-color:#FF9999;
  color:#000;
}
.SELECTED{
  background-color:#FFF501;
  color:#000;
}

.RECEIVED,.ACCEPTED{
  background-color:#99F988;
  color:#000;
}